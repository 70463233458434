import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-estados-credito",
  templateUrl: "./estados-credito.component.html",
  styleUrls: ["./estados-credito.component.css"],
})
export class EstadosCreditoComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
