import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ViewEncapsulation,
} from "@angular/core";

import { PlanillasService } from "../despachos/pages/planillas/services/planillas.service";
import { Planilla } from "../models/planillas.model";
import { PlanillaRutaDespacho } from "../models/planillas_rutasdespachos.model";
import { PlanillaRutaDespachoNewEditComponent } from "../despachos/pages/planillas-rutasdespachos/new-edit/new-edit.component";
import * as moment from "moment";
import Swal from "sweetalert2";
import { NgbActiveModal, NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { PlanillaRutasDespachosService } from "../despachos/pages/planillas-rutasdespachos/services/planillas_rutasdespachos.service";
import { PlanillasLiquidacionService } from "../despachos/pages/planillas-liquidacion/services/planillas-liquidacion.service";
import { cargarPlanillasRegistradas } from "../despachos/pages/planillas/store/actions";
import { Store } from "@ngrx/store";
import { AppState } from "../app.reducers";
import { catchError, delay, finalize, switchMap, tap } from "rxjs/operators";
import { of, throwError } from "rxjs";
import { MensajesToastrService } from "../mensajes/toastr-service";
import { RutasDespachosLugaresService } from "../despachos/pages/rutasdespachos-lugares/services/rutasdespachos-lugares.service";
import { PlanillaLiquidacion } from "../models/planillas-liquidacion.model";

export interface DialogData {
  ruta_id: number;
  nombre: string;
}

@Component({
  selector: "app-dlg-planillas",
  templateUrl: "./dlg-planillas.component.html",
  encapsulation: ViewEncapsulation.None,
  styles: [
    `
      mat-form-field {
        width: 100%;
        font-size: 10px;
      }
      .btndlg.btn.btn-link {
        padding-bottom: 0px;
        padding-top: 4px;
      }
      mat-dialog {
        width: 200%;
      }
    `,
  ],
})
export class DlgPlanillasComponent implements OnInit {
  @Output() submitClicked = new EventEmitter<any>();
  data_model: Planilla[] = [];
  Titulo: string = "Ultima Planilla";
  PlanillaLiquidacion: PlanillaLiquidacion;
  min: number = 0;
  isbtnInactivo: boolean = false;

  constructor(
    public modal: NgbActiveModal,
    private _serviceModel: PlanillasService,
    private modalService: NgbModal,
    private _servicePlanillasRutasDespachos: PlanillaRutasDespachosService,
    private _serviceRdl: RutasDespachosLugaresService,
    private _servicePlanillaLiquidacion: PlanillasLiquidacionService,
    private store: Store<AppState>,
    private _serviceToastr: MensajesToastrService
  ) {}

  ngOnInit() {
    this.data_model = [];
  }

  onNoClick(): void {
    // this.dialogRef.close();
  }

  async BuscarRegistro(buscar: string): Promise<boolean> {
    console.log("2.");
    if (buscar.length > 0) {
      try {
        const resp: any = await this._serviceModel
          .get_ultima_planilla_bybus(buscar)
          .toPromise(); // Convertimos el observable a promesa

        this.data_model = resp.data;
        console.log("3.");
        return true;
      } catch (error) {
        console.error("Error en BuscarRegistro:", error);
        return false;
      }
    } else {
      return false;
    }
  }

  /* async BuscarRegistro(buscar: string): Promise<boolean> {
    console.log("2. BuscarRegistro");
    if (buscar.length > 0) {
      this._serviceModel
        .get_ultima_planilla_bybus(buscar)
        .subscribe((resp: any) => {
          this.data_model = resp.data;
          console.log("3. Fin BuscarRegistro");
          return true;
        });
    } else {
      return false;
    }
  } */

  onSelect(id: number) {
    // this.dialogRef.close();
  }

  async add_ruta(model: PlanillaRutaDespacho) {
    this.isbtnInactivo = true;
    const ref = this.modalService.open(PlanillaRutaDespachoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = false;
    ref.result.then(
      async (data) => {
        const date = data.frm._fecha_planilla;
        const time = data.frm._fechahora_salida;
        const fsalida = combineDateTime(date, time);

        data.frm.fechahora_salida = fsalida;
        let datosliquidar = [];

        const isFechaHoraValida = await this.validarFechaHoraRemota(
          data.frm.fechahora_salida
        );

        if (!isFechaHoraValida) {
          return false;
        } else {
          if (data.valido) {
            this._serviceToastr.showInfo("Creando ruta. Espere un momento...");
            this.min = 0;
            this._servicePlanillasRutasDespachos
              .create(data.frm)
              .pipe(
                switchMap((resp: any) => {
                  // console.log("Respuesta de create:", resp);
                  if (resp.status === "ok") {
                    return this._serviceRdl
                      .getByIdAndPlanilla_id(
                        resp["data"].planillarutadespacho_id,
                        resp["data"].planilla_id
                      )
                      .pipe(delay(200));
                  } else {
                    Swal.fire("Atención", resp.msg, "error");
                    return throwError(resp.msg);
                  }
                }),
                switchMap((resp2: any) => {
                  // console.log("Respuesta de getByIdAndPlanilla_id:", resp2);
                  if (!resp2 || resp2.length === 0) {
                    Swal.fire(
                      "Atención",
                      "No se encontraron datos para liquidar.",
                      "error"
                    );
                    return throwError("No se encontraron datos para liquidar.");
                  }

                  const planillaLiquidaciones = resp2.map(
                    (dato: any) =>
                      new PlanillaLiquidacion(
                        0,
                        dato.planillarutadespacho_id,
                        dato.lugarcontrol_id,
                        dato.minuto,
                        dato.minutos_retardo_sancion,
                        dato.valor_minuto_sancion,
                        this.sumarMinutos(fsalida, dato.minuto)
                      )
                  );

                  return this._servicePlanillaLiquidacion
                    .insert_recorrido(planillaLiquidaciones)
                    .pipe(
                      tap((response: any) => {
                        /* console.log(
                          "Respuesta del servicio insert_recorrido ============>:",
                          response
                        ); */
                      })
                    );
                }),
                tap((response: any) => {
                  this._serviceToastr.showSuccess(response.msg);
                }),
                tap(async () => {
                  this._serviceToastr.showSuccess(
                    "Ruta creada correctamente..."
                  );
                  console.log("1. result");
                  const result = await this.BuscarRegistro(
                    this.data_model[0].numero
                  );
                  console.log("4. result", result);
                  if (result) {
                    this.isbtnInactivo = false;
                  }
                }),
                catchError((error) => {
                  this.isbtnInactivo = false;
                  Swal.fire("Atención", `Ocurrió un error: ${error}`, "error");
                  return of(null);
                }),
                finalize(() => {
                  this._serviceToastr.showInfo("Proceso finalizado.");
                })
              )
              .subscribe((resp: any) => {
                if (resp) {
                  //this.seleccion_query();
                }
              });
          } else {
            this.isbtnInactivo = false;
            Swal.fire("Atención", "Los datos no son válidos", "error");
          }
        }
      },
      (cancel) => {
        this.isbtnInactivo = false;
      }
    );
  }

  delete_ruta_asignada(model: any) {
    // console.log(model);
    Swal.fire({
      title:
        '<span style="font-size: 20px;"> Seguro de eliminar el registro?</span>',
      html:
        "<p><b>Ruta:</b> " +
        model.rutadespacho_nombre +
        " - " +
        moment(model.fechahora_salida).format("HH:mm") +
        "</p>",
      icon: "question",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        this._servicePlanillasRutasDespachos
          .get_delete_ruta_asignada(model.planillarutadespacho_id.toString())
          .subscribe((resp: any) => {
            if (resp.status === "ok") {
              this.BuscarRegistro(model.numero);
              Swal.fire(
                "Información",
                "El registro ha sido eliminado...",
                "success"
              );
            } else {
              Swal.fire("Atención", resp.msg, "warning");
            }
          });
      }
    });
  }

  edit_ruta(model: Planilla) {
    const ref = this.modalService.open(PlanillaRutaDespachoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planilla_id = model.planilla_id;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceModel
            .update(model.planilla_id.toString(), data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.BuscarRegistro(this.data_model[0].numero);
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }

  sumarMinutos(fecha: Date, minutos: number): Date {
    let nuevaFecha = new Date(fecha.getTime());
    this.min += minutos;
    nuevaFecha.setMinutes(nuevaFecha.getMinutes() + this.min);

    return nuevaFecha;
  }

  async validarFechaHoraRemota(value: any): Promise<boolean> {
    // console.log("validarFechaHoraRemota");
    let fsalida = value;
    try {
      const resp: any = await this._servicePlanillasRutasDespachos
        .get_fechahora_salida(fsalida, this.data_model[0].vehiculo_id)
        .toPromise();

      if (resp.registros > 0) {
        this.isbtnInactivo = false;
        Swal.fire(
          "Atención",
          "Hora de Salida <strong>" +
            moment(resp.data.fechahora_salida).format("YYYY-MM-DD HH:mm") +
            "</strong> ya asignada:<br> " +
            "<strong>Vehículo:</strong> " +
            resp.data.numero +
            "<br><strong>Ruta:</strong> " +
            resp.data.rutadespacho_nombre,
          "warning"
        );
        return false;
      } else {
        return true;
      }
    } catch (error) {
      Swal.fire("Atención", error, "warning");
      return false;
    }
  }
}

function combineDateTime(dateString: string, timeString: string): Date {
  const [day, month, year] = dateString.split("-").map(Number);
  const [hours, minutes] = timeString.split(":").map(Number);
  return new Date(year, month - 1, day, hours, minutes);
}
