import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { URL_API_NESTJS } from "src/app/config/config";

@Injectable({
  providedIn: "root",
})
export class CreditosService {
  private url = URL_API_NESTJS;

  constructor(private http: HttpClient) {}

  get_simulador_credito(
    monto: number,
    tasa: number,
    cuotas: number,
    isround_cuota: number,
    fechaprimerpago: string,
    credito_id: number,
    is_simular: number
  ) {
    return this.http
      .get(
        `${this.url}/creditos/get_simulador_credito/${monto}/${tasa}/${cuotas}/${isround_cuota}/${fechaprimerpago}/${credito_id}/${is_simular}`
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }
}
