import { Component, OnInit } from "@angular/core";
import { ParametrosCreditoService } from "./services/parametros-credito.service";
import { ParametroCreditoModel } from "./models/parametros-credito-model";
import { BlockTemplateComponent } from "src/app/templates/block-template.component";
import Swal from "sweetalert2";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ParametrosCreditoNewEditComponent } from "./new-edit/new-edit.component";

@Component({
  selector: "app-parametros-credito",
  templateUrl: "./parametros-credito.component.html",
  styleUrls: ["./parametros-credito.component.css"],
})
export class ParametrosCreditoComponent implements OnInit {
  data_parametros_credito: ParametroCreditoModel;
  blockTemplate!: BlockTemplateComponent;

  constructor(
    private _serviceParametrosCredito: ParametrosCreditoService,
    private modalService: NgbModal
  ) {}

  ngOnInit() {
    this.get_lista();
  }

  get_lista() {
    this._serviceParametrosCredito
      .get_lista()
      .subscribe((resp: ParametroCreditoModel) => {
        this.data_parametros_credito = resp;
        console.log("data_parametros_credito: ", this.data_parametros_credito);
      });
  }

  get_byId() {
    this._serviceParametrosCredito
      .getById(1)
      .subscribe((resp: ParametroCreditoModel) => {
        this.data_parametros_credito = resp;
        console.log("data_parametros_credito: ", this.data_parametros_credito);
      });
  }

  new() {}

  edit(model: ParametroCreditoModel) {
    const ref = this.modalService.open(ParametrosCreditoNewEditComponent, {
      centered: true,
      size: <any>"xl",
      backdrop: "static",
    });
    ref.componentInstance._model = model;
    ref.componentInstance.isEdit = true;
    ref.componentInstance._planillaparametro_id = model.copparametrocredito_id;
    ref.result.then(
      (data) => {
        if (data.valido) {
          this._serviceParametrosCredito
            .update(model.copparametrocredito_id, data.frm)
            .subscribe((resp: any) => {
              if (resp.status === "ok") {
                this.get_byId();
                Swal.fire("Atención", resp.msg, "success");
              } else {
                Swal.fire("Atención", resp.msg, "error");
              }
            });
        } else {
          Swal.fire("Atención", "Los datos no son válidos", "error");
        }
      },
      (cancel) => {}
    );
  }
}
