import { Component, Input, OnInit, ViewEncapsulation } from "@angular/core";
import { ReportesDespachosService } from "../services/reportes-despachos.service";
import Swal from "sweetalert2";
import * as XLSX from "xlsx";
import { DatePipe } from "@angular/common";
import * as moment from "moment";
@Component({
  selector: "app-rpt-conceptos",
  encapsulation: ViewEncapsulation.None,
  templateUrl: "rpt-conceptos.component.html",
  styles: [],
})
export class RptConceptosComponent implements OnInit {
  @Input() data_rpt = [];
  @Input() chkBus = false;
  @Input() _bus = "";
  @Input() tituloReporte = "";
  @Input() tiporpt_id = 0;
  fileName = "";
  FechaDesde = "";
  FechaHasta = "";
  Empresa = "";
  loading: boolean = false;
  _mes = "";

  constructor(
    private _serviceReportesDespachos: ReportesDespachosService,
    private datePipe: DatePipe
  ) {
    this._serviceReportesDespachos.EventDatosAhorros$.subscribe(
      async (resp: any) => {
        this.data_rpt = resp;
        this.data_rpt.forEach((item) => {
          item.numero = parseInt(item.numero);
        });
      }
    );

    this._serviceReportesDespachos.FechaDesde$.subscribe((resp: any) => {
      this.FechaDesde = resp;
    });
    this._serviceReportesDespachos.FechaHasta$.subscribe((resp: any) => {
      this.FechaHasta = resp;
    });

    this._serviceReportesDespachos.mes$.subscribe((resp: any) => {
      this._mes = resp;
    });

    this._serviceReportesDespachos.bus$.subscribe((resp: any) => {
      this._bus = resp;
    });
  }

  ngOnInit() {}

  exportAsXLSX(): void {
    this.loading = true;
    const { DateTime } = require("luxon");
    const strDate = DateTime.now().toFormat("yyyy-MM-dd");
    let filename: string = `PRODUCIDOS_${strDate}.xlsx`;

    let periodo =
      "Desde: " +
      moment(this.FechaDesde).format("DD-MM-YYYY") +
      " - Hasta: " +
      moment(this.FechaHasta).format("DD-MM-YYYY");

    this._serviceReportesDespachos.downloadFile_Producidos(
      this.data_rpt,
      filename,
      periodo
    );
  }

  get_Total_Ahorro() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.valor_ahorro_propietario);
    });
    return total;
  }

  get_Total_AbonoSS() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.abono_ss_conductor);
    });
    return total;
  }

  get_Total_AdicionalSS() {
    let total = 0;
    this.data_rpt.forEach((x) => {
      total += parseFloat(x.adicional_aporte_ss);
    });
    return total;
  }
}
