import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";
import { URL_API_NESTJS } from "src/app/config/config";
import { ParametroCreditoModel } from "../models/parametros-credito-model";

@Injectable({
  providedIn: "root",
})
export class ParametrosCreditoService {
  private url = URL_API_NESTJS;
  httpOptions = {};

  constructor(private http: HttpClient) {}

  get_lista() {
    return this.http.get(`${this.url}/cop-parametros-credito`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  getById(id: number) {
    return this.http.get(`${this.url}/cop-parametros-credito/${id}`).pipe(
      map((resp) => {
        return resp;
      })
    );
  }

  update(id: number, model: ParametroCreditoModel) {
    console.log("model ==> ", model);
    return this.http.put(`${this.url}/cop-parametros-credito/${id}`, model);

    /* this.loadAuth();
    return this.http.put(
      `${this.url}/cop-parametros-credito/update/${id}`,
      model,
      this.httpOptions
    ); */
  }

  loadAuth() {
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json; charset=utf-8",
        authorization: `Bearer ${sessionStorage.getItem("token")}`,
      }),
    };
  }
}
