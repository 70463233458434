import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { SimuladorCreditoComponent } from "./pages/simulador-credito/simulador-credito.component";
import { PageNotFoundComponent } from "../components/page-not-found/page-not-found.component";
import { LineasCreditoComponent } from "./pages/lineas-credito/lineas-credito.component";
import { ParametrosCooperativaComponent } from "./pages/parametros/parametros-cooperativa/parametros-cooperativa.component";
import { EstadosCreditoComponent } from "./pages/dialogos/estados-credito/estados-credito.component";
import { ParametrosCreditoComponent } from "./pages/parametros/parametros-credito/parametros-credito.component";

const routes: Routes = [
  {
    path: "",
    children: [
      {
        path: "simulador-credito",
        component: SimuladorCreditoComponent,
      },
      {
        path: "lineas-credito",
        component: LineasCreditoComponent,
      },
      {
        path: "estados-credito",
        component: EstadosCreditoComponent,
      },
      {
        path: "parametros",
        component: ParametrosCooperativaComponent,
      },
      {
        path: "parametros-credito",
        component: ParametrosCreditoComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
})
export class CooperativaRoutingModule {}
