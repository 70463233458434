import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CooperativaRoutingModule } from "./cooperativa-routing.module";
import { SimuladorCreditoComponent } from "./pages/simulador-credito/simulador-credito.component";
import { CreditosComponent } from "./pages/creditos/creditos.component";
import { ParametrosCreditoComponent } from "./pages/parametros/parametros-credito/parametros-credito.component";
import { EstadosCreditoComponent } from "./pages/dialogos/estados-credito/estados-credito.component";
import { ParametrosCooperativaComponent } from "./pages/parametros/parametros-cooperativa/parametros-cooperativa.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BlockTemplateComponent } from "./templates/block-template.component";
import { BlockUIModule } from "ng-block-ui";
import {
  NgbModule,
  NgbTimepickerModule,
  NgbTooltipConfig,
} from "@ng-bootstrap/ng-bootstrap";
import { ToastrModule } from "ngx-toastr";
import {
  MatCardModule,
  MatDatepickerModule,
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
} from "@angular/material";
import { IConfig, NgxMaskModule } from "ngx-mask";
import { LineasCreditoComponent } from "./pages/lineas-credito/lineas-credito.component";
import { PanelComponent } from "./pages/panel/panel.component";
import { ParametrosCreditoNewEditComponent } from "./pages/parametros/parametros-credito/new-edit/new-edit.component";

const maskConfig: Partial<IConfig> = {
  validation: false,
  // showMaskTyped: true,
  // clearIfNotMatch: true,
};

@NgModule({
  declarations: [
    SimuladorCreditoComponent,
    CreditosComponent,
    ParametrosCreditoComponent,
    EstadosCreditoComponent,
    ParametrosCooperativaComponent,
    BlockTemplateComponent,
    LineasCreditoComponent,
    PanelComponent,
    ParametrosCreditoNewEditComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    NgbModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatCardModule,
    MatSelectModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    NgbTimepickerModule,
    NgxMaskModule.forRoot(maskConfig),
    BlockUIModule.forRoot({
      template: BlockTemplateComponent,
    }),
    ToastrModule.forRoot(),
    CooperativaRoutingModule,
  ],
  entryComponents: [BlockTemplateComponent, ParametrosCreditoNewEditComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    NgbTooltipConfig,
    ToastrModule,
    {
      provide: LOCALE_ID,
      useValue: "en-US",
    },
  ],
})
export class CooperativaModule {}
