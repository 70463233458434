export class PlanillaLiquidacion {
  constructor(
    public planillaliquidacion_id: number,
    public planillarutadespacho_id: number,
    public lugarcontrol_id: number,
    public minuto: number,
    public minutos_retardo_sancion: number,
    public valor_minuto_sancion: number,
    public tiempo_paso: Date
  ) {}
}
// public planillaparametro_id: number,
// public valor_ahorro_propietario: number,
// public subtotal_minutos_retardo: number,
// public valor_adicional_ss: number,
// public valor_abono_ss: number
