import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { ParametrosCreditoService } from "../services/parametros-credito.service";
import { ParametroCreditoModel } from "../models/parametros-credito-model";
import { BlockUI, NgBlockUI } from "ng-block-ui";

@Component({
  selector: "app-new-edit",
  templateUrl: "./new-edit.component.html",
  styleUrls: ["./new-edit.component.css"],
})
export class ParametrosCreditoNewEditComponent implements OnInit {
  @BlockUI("data-section") blockUI!: NgBlockUI;
  _userSession: any = "0";
  frm: FormGroup;
  isEdit: boolean = false;
  _model: ParametroCreditoModel;

  constructor(
    private fb: FormBuilder,
    public modal: NgbActiveModal,
    private _serviceModel: ParametrosCreditoService
  ) {}

  ngOnInit() {
    this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));

    this.frm = this.fb.group({
      copparametrocredito_id: [0],
      antiguedad_socio: [0, Validators.required],
      dias_limite_intereses: [0, Validators.required],
      interes_mora: [0, Validators.required],
      max_creditos_codeudor: [0, Validators.required],
      max_creditos_deudor: [0, Validators.required],
      monto_maximo_aprueba: [0, Validators.required],
    });
    if (this.isEdit) {
      this.blockUI.start("Consultando datos. Espere un momento...");
      const id = this._model.copparametrocredito_id;
      this.setForm(id);
    }
  }

  get fdata() {
    return this.frm.controls;
  }

  private setForm(id: number) {
    this._serviceModel.getById(id).subscribe((resp: any) => {
      this._userSession = JSON.parse(sessionStorage.getItem("currentUser"));
      this._model = resp[0];
      console.log(this._model);

      this.frm = this.fb.group({
        copparametrocredito_id: [this._model.copparametrocredito_id],
        antiguedad_socio: [this._model.antiguedad_socio, Validators.required],
        dias_limite_intereses: [
          this._model.dias_limite_intereses,
          Validators.required,
        ],
        interes_mora: [this._model.interes_mora, Validators.required],
        max_creditos_codeudor: [
          this._model.max_creditos_codeudor,
          Validators.required,
        ],
        max_creditos_deudor: [
          this._model.max_creditos_deudor,
          Validators.required,
        ],
        monto_maximo_aprueba: [
          this._model.monto_maximo_aprueba,
          Validators.required,
        ],
      });
      this.blockUI.stop();
    });
  }

  solo_numeros(value: string, ctrl: string) {
    const lastchar = value.substring(value.length - 1);
    if (!new RegExp("[0-9]").test(lastchar)) {
      value = value.substring(0, value.length - 1);
      this.frm.controls[ctrl].setValue(value);
    }
  }
}
